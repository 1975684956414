/* global Promise, config, moment */

'use strict';

angular.module('managerApp').controller('PrepaidAccountsController', ($scope,
        $route, $location, $routeParams, $cookies, $mdDialog, $_PrepaidAccounts, procedureTools, clients) => {

    const path = $location.path();
    $scope.config = config;
    $scope.prepaidAccountId = $routeParams.id;
    $scope.prepaidAccount = null;
    $scope.prepaidAccounts = null;
    $scope.isItem = typeof $scope.prepaidAccountId !== 'undefined';

//------------------------------------------------------------------------------

    const TEMPLATES_DIR = config.app.templatesDir;
    const DEFAULT_ERROR_MESSAGE = config.app.defaultErrorMessage;
    const prepaidAccountEventsPaginationCookie = 'pagination.prepaidAccount.events.itemsPerPage';

//------------------------------------------------------------------------------

    function getPrepaidAccounts() {
        $scope.params = [];
        $_PrepaidAccounts.get().then((data) => {
            $scope.prepaidAccounts = data;
            $scope.$apply();
        });
    }

    $scope._events = {
        pagination: {
            maxSize: 5,
            totalItems: 0,
            currentPage: 1,
            itemsPerPage: $cookies.get(prepaidAccountEventsPaginationCookie) || 10,
            type: null,
            pageChanged: function () {
                $cookies.put(prepaidAccountEventsPaginationCookie, this.itemsPerPage);
                $scope._events.get($scope.prepaidAccount._id, this.type);
            }
        },
        onTabSelect: function (type) {
            this.pagination.type = type;
            this.pagination.pageChanged();
        },
        getMethodLabel: function (method) {
            switch (method) {
                case 'paiement':
                    return 'Paiement pour une démarche';
                case 'remboursement':
                    return 'Remboursement';
                case 'depot':
                    return 'Dépôt';
                default:
                    return null;
            }
        },
        get: function (prepaidAccountId, type) {
            if (!prepaidAccountId) {
                return;
            }
            let params = {};
            if (type && ['debit', 'credit'].includes(type)) {
                params.type = type;
            }
            $_PrepaidAccounts.getEvents(
                    prepaidAccountId, params,
                    this.pagination.currentPage,
                    this.pagination.itemsPerPage).then((_data) => {
                let events = [];
                _data.rows.forEach((row) => {
                    let item = Object.assign({}, row);
                    if (row.method) {
                        item.methodLabel = this.getMethodLabel(row.method);
                    }
                    if (row.details) {
                        let details = this.parseEventDetails(row.details);
                        if (details) {
                            item = Object.assign(details, item);
                        }
                    }
                    events.push(item);
                });
                $scope.prepaidAccount.events = events;
                this.pagination.totalItems = _data.count;
                $scope.$apply();
            });
        },
        parseEventDetails: function (details) {
            let result = {};
            if (typeof details === 'string' && details !== '') {
                try {
                    let data = JSON.parse(details);
                    if (data) {
                        if (data.procedure) {
                            result.procedure = {
                                number: data.procedure.number,
                                type: data.procedure.type,
                                name: procedureTools.getProcedureName(data.procedure.type)
                            };
                        }
                        if (data.vehicle) {
                            result.vehicle = {
                                make: data.vehicle.make,
                                registrationNum: data.vehicle.registrationNum
                            };
                        }
                    }
                    return result;
                } catch (e) {
                    return null;
                }
            }
            return null;
        }
    };

    function getPrepaidAccount(id) {
        $_PrepaidAccounts.get(id).then((data) => {
            $scope.client = data.Client;
            $scope.prepaidAccount = data;
            $scope.$apply();
            $scope._events.pagination.pageChanged();
        });
    }

    function getPartners() {
        return clients.getPartners().then((result) => {
            return Promise.resolve(result);
        });
    }

    $scope.showCreditPopup = () => {
        $mdDialog.show({
            templateUrl: TEMPLATES_DIR + 'credit.prepaid.account.html',
            parent: angular.element(document.body),
            fullscreen: true,
            locals: {
                prepaidAccountId: $scope.prepaidAccount._id,
                clientCompany: $scope.prepaidAccount.Client.company
            },
            controller: ($scope, toast, prepaidAccountId, clientCompany) => {
                $scope.clientCompany = clientCompany;
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        $scope.prepaidAccountId = prepaidAccountId;
                        $_PrepaidAccounts.credit($scope.prepaidAccountId, {
                            amount: Number($scope.amount)
                        }).then((res) => {
                            console.log(res);
                            toast.show('Le coacquéreur a bien été ajouté à cette démarche.', 'success');
                            $route.reload();
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        });
                    }//
                };
                setTimeout(() => {
                    document.getElementById('amount').focus();
                }, 500);
            }
        }).then((answer) => {
            console.log('answer : ' + answer);
        }, () => {
            console.log('You cancelled the dialog.');
        });
    };

    $scope.showCreatePrepaidAccountPopup = () => {
        $mdDialog.show({
            templateUrl: TEMPLATES_DIR + 'create.prepaid.account.html',
            parent: angular.element(document.body),
            fullscreen: true,
            locals: {},
            controller: ($scope, toast) => {
                $scope.partners = [];
                $scope.selectedPartner = null;

                $scope.selectPartner = (partner) => {
                    $scope.selectedPartner = partner;
                    $scope.selectedPartner.actualBalance = null;
                    setTimeout(() => {
                        let elem = document.getElementsByTagName('md-dialog-content')[0];
                        elem.scroll({top: elem.scrollHeight, behavior: 'smooth'});
                    }, 0);
                };

                getPartners().then((partners) => {
                    $scope.partners = partners;
                });

                $scope.closeDialog = (confirm = false) => {
                    if (confirm) {
                        let data = {
                            actualBalance: parseInt($scope.selectedPartner.actualBalance) || null,
                            allocatedTerminalsBalance: parseInt($scope.selectedPartner.allocatedTerminalsBalance) || null,
                            allocatedTabletsBalance: parseInt($scope.selectedPartner.allocatedTabletsBalance) || null,
                            terminals: $scope.selectedPartner.terminals || null,
                            tablets: $scope.selectedPartner.tablets || null
                        };
                        clients.createPrepaidAccount($scope.selectedPartner._id, data).then(() => {
                            toast.show('Le compte prépayé a bien été créé.', 'success');
                            $mdDialog.hide();
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : 'Une erreur est survenue lors du traitement de votre demande.', 'error');
                        });
                    } else {
                        $mdDialog.hide();
                }
                };
            }
        }).then((answer) => {
        });
    };

//------------------------------------------------------------------------------

    if ($scope.isItem) {
        getPrepaidAccount($scope.prepaidAccountId);
    } else {
        getPrepaidAccounts();
    }

});
